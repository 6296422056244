import * as React from "react";
import { useWizard } from "react-use-wizard";
import { BookingService } from "../../api/api.service";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { ro } from "date-fns/locale";
import { toast } from "react-toastify";
import NextButton from "../../components/NextButton";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "../../hooks/customHook";

registerLocale("ro", ro);

export default function Step1({
  booking,
  setBooking,
  bookingId,
  setBookingId,
  isLoadingCustom,
  setIsLoadingCustom,
  setSelectedPickupPoint,
  setPickupPoints,
  setIsFormSubmitted,
  setIsBookingCancelled,
}: any) {
  const { isLoading, nextStep } = useWizard();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const minDate = format(new Date(), "yyyy-MM-dd");

  const clearState = () => {
    setBooking({});
    setBookingId("");
    setPickupPoints([]);
    setSelectedPickupPoint(null);
  };

  const handleStep1 = async (paramId: string, paramDate: Date) => {
    const id = paramId || bookingId;
    const date = paramDate || booking.start_date;
    if (!id || !date) {
      toast.error("Please fill in all fields!");
      return;
    }

    setIsLoadingCustom(true);

    try {
      let result = await BookingService.getBooking(id, format(date, "yyyy-MM-dd"));

      if (result.isSuccess && result.response?.status === "success") {
        // if booking is not cancelled
        if (!result.response.data.is_cancelled) {
          setBooking(result.response.data);

          if (result.response.data.has_pickup) {
            setSelectedPickupPoint(result.response.data.pickup_id);
          }

          setPickupPoints(result.response.data.pickup_points);

          nextStep();
        } else {
          setIsBookingCancelled(true);
          setIsFormSubmitted(true);
          clearState();
        }
      } else {
        toast.error("Booking not found!");
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    }

    setIsLoadingCustom(false);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate >= minDate) {
      setBooking({ ...booking, start_date: selectedDate });
    } else {
      alert("Please select a date from today or later.");
      // Optionally, you can reset the date input to the minimum date
      setBooking({ ...booking, start_date: minDate });
    }
  };
  useEffect(() => {
    if (booking.start_date && booking.start_date >= minDate) {
      // Add a small delay before setting focus to ensure proper behavior on iOS
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [booking.start_date, minDate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const encoded = queryParams.get("ref");
    if (!encoded) return;
    const decoded = atob(encoded);
    if (!decoded) return;
    const [dateString, id] = decoded.split(",");
    if (!dateString || !id) return;
    const date = new Date(dateString);

    if (date && id) {
      setBookingId(id);
      setBooking({ ...booking, start_date: date });
      handleStep1(id, date);
    }
  }, []);
  return (
    <>
      <div className="form-register">
        <div className="content ">
          <div className="inner">
            {isLoading && <p>Loading...</p>}
            <h3>Enter Booking Details:</h3>
            <div className="form-row">
              <div className="form-holder form-holder-2">
                <label className="form-row-inner">
                  {useIsMobile().isMobile ? (
                    <input
                      type="date"
                      className="form-control"
                      onChange={handleDateChange}
                      value={booking.start_date}
                      required
                      min={format(new Date(), "yyyy-MM-dd")}
                    />
                  ) : (
                    <DatePicker
                      selected={booking.start_date ? new Date(booking.start_date) : null}
                      onChange={(date: Date | null) => {
                        setBooking({ ...booking, start_date: date });
                        setTimeout(() => {
                          setIsDatePickerOpen(false);
                          if (inputRef.current) {
                            inputRef.current.focus();
                          }
                        }, 100);
                      }}
                      onClickOutside={() => setIsDatePickerOpen(false)}
                      onInputClick={() => setIsDatePickerOpen(true)}
                      open={isDatePickerOpen}
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                      locale="en"
                    />
                  )}

                  <span className="label">Start Date</span>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-holder form-holder-2">
                <label className="form-row-inner">
                  <input
                    type="text"
                    className="form-control"
                    id="bookingId"
                    name="bookingId"
                    value={bookingId}
                    ref={inputRef}
                    // onFocus={e => e.persist()}
                    onChange={(e) => setBookingId(e.target.value)}
                    required
                  />
                  <span className="label">Booking ID</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions ">
        <NextButton text={"Find my booking"} isLoadingCustom={isLoadingCustom} handleFunction={handleStep1} />
      </div>
    </>
  );
}
